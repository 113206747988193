import API from "@/assets/core/axios-instance.core";
import {
  GetBadgeCountPayload,
  GetBadgeCountResponse,
  GetExcelRewardPayload,
  GetRewardsPayload,
  GetRewardsResponse,
} from "@/stores/reward/dto";

class RewardService {
  async getRewards(payload: GetRewardsPayload): Promise<GetRewardsResponse> {
    return API.post(
      `/reward-transaction-monthly/get-all`,
      JSON.parse(JSON.stringify(payload))
    )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((res) => Promise.reject(res.response.data));
  }

  async getBadgeCount(
    payload: GetBadgeCountPayload
  ): Promise<GetBadgeCountResponse> {
    return API.post(
      `/reward-transaction-monthly/get-badge`,
      JSON.parse(JSON.stringify(payload))
    )
      .then((res) => Promise.resolve(res.data))
      .catch((res) => Promise.reject(res.response.data));
  }

  async updateStatusRewards(payload: any): Promise<any> {
    return API.post(
      `/reward-transaction-monthly/update-status`,
      JSON.parse(JSON.stringify(payload))
    )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((res) => Promise.reject(res.response.data));
  }

  async getExcelReward(payload: GetExcelRewardPayload): Promise<any> {
    return API.post(
      `/export-excel/excel-reward`,
      JSON.parse(JSON.stringify(payload)),
      {
        responseType: "arraybuffer",
      }
    )
      .then((res) => Promise.resolve(res))
      .catch((res) => Promise.reject(res.response.data));
  }
}
export default new RewardService();
