import RewardService from "@/services/partners/Reward.service";
import { RewardState } from ".";
import {
  __GetBadgeCount,
  __GetExcelReward,
  __GetRewards,
  __UpdateStatusRewards,
} from "./action-dto";

/* library toast */
import "vue3-toastify/dist/index.css";

/* Service */
import ToastService from "@/services/Toast.service";

export default {
  GET_REWARDS(state: RewardState, payload: __GetRewards) {    
    state.is_loading_rewards = true;
    state.is_error_rewards = null;

    RewardService.getRewards(payload)
      .then((res) => {
        state.is_error_rewards = null;
        state.rewards = res.data;
        if (res.pagination) state.pagination = { ...res.pagination };
      })
      .catch((err) => {
        state.is_error_rewards = "Found an error.";
      })
      .finally(() => {
        state.is_loading_rewards = false;
      });
  },
  GET_WAIT_FOR_REQUEST_COUNT(state: RewardState, payload: __GetBadgeCount) {
    RewardService.getBadgeCount({ ...payload, status: "Request" })
      .then((res) => {
        state.is_error_rewards = null;
        state.wait_for_request_count = res.data;
      })
      .catch((err) => {
        state.wait_for_request_count = 0;
      });
  },

  GET_PENDING_COUNT(state: RewardState, payload: __GetBadgeCount) {
    RewardService.getBadgeCount({ ...payload, status: "Pending" })
      .then((res) => {
        state.is_error_rewards = null;
        state.pending_count = res.data;
      })
      .catch((err) => {
        state.pending_count = 0;
      });
  },

  UPDATE_STATUS_REWARDS(state: RewardState, payload: __UpdateStatusRewards) {
    RewardService.updateStatusRewards({ ...payload, status: "Pending" })
      .then((res) => {
        state.is_error_rewards = null;
      })
      .catch((err) => {
        return;
      });
  },

  GET_EXCEL_REWARD(state: RewardState, payload: __GetExcelReward) {
    RewardService.getExcelReward(payload)
      .then((res) => {
        const data = res.data as any; // as any for excel
        const blob = new Blob([data], { type: res.headers["content-type"] });
        const url = window.URL.createObjectURL(blob);
        const fileName =
          "Affiliate_result_export_as_of_" +
          payload.dateStart +
          "-" +
          payload.dateEnd +
          ".xlsx";
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        return ToastService.showWarning("Downloading excel file error");
      });
  },
};
