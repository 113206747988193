
/* import */
import { __GetCampaignDropdown } from "@/stores/result/action-dto";
import ICampaignDropdown from "@/types/core/campaign-dropdown.type";
import { defineComponent } from "vue";

/* Library toast */
import "vue3-toastify/dist/index.css";

/* Service */
import ToastService from "@/services/Toast.service";

export default defineComponent({
  setup() {
    return {};
  },
  /* data */
  data() {
    return {
      value: null as string | null,
      campaignSelected: null as ICampaignDropdown | null,
      debounceTimeSearch: null as any,
      old: null as any,
    };
  },
  /* props */
  props: {
    /* props data */
    msg: {
      type: String,
      required: true,
      default: () => "",
    },
    data: {
      type: Object,
      required: true,
      default: () => null,
    },

    /* props stye */
    width: {
      type: String,
      required: false,
      default: () => "100%",
    },
    campaignChange: {
      type: null,
      required: true,
      default: () => "",
    },
    partnerCode: {
      type: String,
      default: () => "",
    },
  },

  /* -------------------------------------------------------------------------- */
  //*                                   methods                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    getCampaignStatusIcon(status) {
      return status === true
        ? require("@/assets/svgs/icons/active.svg")
        : require("@/assets/svgs/icons/inactive.svg");
    },
    setCampaignSelected(campaignCode, value) {
      if (campaignCode !== null && value !== null) {
        this.value = value;
        this.campaignSelected = campaignCode;
        this.$emit("campaignSelected", campaignCode);
      }
    },
    checked(e) {
      const cur = e === "" ? null : e;
      if (cur === null) {
        // ToastService.showWarning("Please selected name campaign");
      } else return;
    },
    styles() {
      const styles: { [key: string]: string } = {};
      /* Validate */
      if (this.value === null || this.value === "") {
        styles["border"] = "1px solid red";
      }
      return styles;
    },
  },

  /* -------------------------------------------------------------------------- */
  //*                                    watch                                   */
  /* -------------------------------------------------------------------------- */
  watch: {
    checkedCampaignChange(old, cur) {
      setTimeout(() => {
        if (this.value === null) {
          if (!old) return;
          const nameCampaign = this.data.filter((x) => x.campaignCode === old);
          this.value = nameCampaign[0]?.nameCampaign;
        }
      }, 500);
    },
    value: {
      handler: function (oldValue, newValue) {
        const old = oldValue ? oldValue.toString() : null;
        const newV = newValue ? newValue.toString() : null;

        if (newV !== old) {
          if (newValue !== null) {
            if (this.debounceTimeSearch) {
              clearTimeout(this.debounceTimeSearch);
              this.debounceTimeSearch = null;
            }
            this.debounceTimeSearch = setTimeout(() => {
              // Get Partner Code
              const partnerCode = this.partnerCode || "";
              // Get Campaign Dropdown
              const payload = { partnerCode, search: oldValue };
              this.$store.dispatch("getCampaignDropdown", payload);
            }, 300);
          }
        }
      },
      immediate: false, // Set this to true if you want to trigger the handler immediately on component initialization
    },
  },

  /* -------------------------------------------------------------------------- */
  //*                                  computed                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    isNoCampaign(): boolean {
      return this.data.length === 0;
    },
    checkedCampaignChange() {
      return this.$props.campaignChange;
    },
  },
});
