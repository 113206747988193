
/* import */
import { GetCampaignsPartnerPayload } from "@/stores/campaign-partner/dto";
import { defineComponent } from "vue";
import stores from "@/stores";
import ICampaignPartner from "@/types/core/campaign-partner.type";
import IUser from "@/types/core/user.type";
import EGoal from "@/types/core/goal.type";
import IPagination from "@/types/api/pagination.type";

/* component shared */
import Pagination from "@/components/shared/pagination/Pagination.vue";
import Card from "@/components/shared/card/Card.vue";
import VTab from "@/components/shared/tab/VTab.vue";
import InputCheckBox from "@/components/shared/input/inputCheckBox.vue";

/* library toast */
import "vue3-toastify/dist/index.css";

/* VIEWS COMPONENT */

/* Service */
import ToastService from "@/services/Toast.service";

export default defineComponent({
  name: "CampaignsPage",
  components: { Card, Pagination, VTab, InputCheckBox },
  data() {
    return {
      campaignFilter: {
        partnerCode: "",
        status: "All",
        goals: [],
        sortBy: null,
        sortDictionary: null,
        pagination: {
          length: 10,
          page: 1,
        },
      } as GetCampaignsPartnerPayload,
      campaigns: [] as ICampaignPartner[],
      goal: EGoal,
      debounceTimeAction: null as any,
      tabCampaign: [
        {
          id: 1,
          name: "All",
        },
        {
          id: 2,
          name: "Active",
        },
        {
          id: 3,
          name: "Inactive",
        },
      ],
      /* IMG SORT SVG */
      arrow_down: require("@/assets/svgs/icons/sort/arrow_down.svg"),
      arrow_up: require("@/assets/svgs/icons/sort/arrow_up.svg"),
      arrow_null: require("@/assets/svgs/icons/sort/arrow.svg"),
      onToggle: false as boolean,
    };
  },
  mounted() {
    this.campaignFilter.partnerCode = this.user?.partnerCode || "";
  },
  methods: {
    pageNow(value) {
      if (value > this.pagination.totalPages || value < 1) {
        this.campaignFilter.pagination.page = 1;
        return;
      }
      this.campaignFilter.pagination.page = value;
    },
    sortStartDate() {
      this.oppositeSortDictionary("START_DATE");
    },
    sortEndDate() {
      this.oppositeSortDictionary("END_DATE");
    },

    /* Change sort direction */
    oppositeSortDictionary(sortBy: "START_DATE" | "END_DATE" | null) {
      const sortByNow = this.campaignFilter.sortBy;
      const sortDictionaryNow = this.campaignFilter.sortDictionary;

      if (sortBy !== sortByNow) {
        this.campaignFilter.sortBy = sortBy;
        this.campaignFilter.sortDictionary = "DESC";
        return;
      }

      if (sortDictionaryNow) {
        if (sortDictionaryNow === "DESC") {
          this.campaignFilter.sortDictionary = "ASC";
        } else {
          this.campaignFilter.sortBy = null;
          this.campaignFilter.sortDictionary = null;
        }
      } else {
        this.campaignFilter.sortDictionary = "DESC";
      }
    },

    onChangeGoalFilter(value: keyof typeof EGoal, status: boolean) {
      if (status) {
        if (!this.campaignFilter.goals.find((item) => item === EGoal[value])) {
          this.campaignFilter.goals.push(EGoal[value]);
        }
      } else {
        this.campaignFilter.goals = this.campaignFilter.goals.filter(
          (goal) => goal !== value
        );
      }
    },

    /* Change number per page */
    changeNumberPerPage(e) {
      this.campaignFilter.pagination = {
        length: 10,
        page: Number(e),
      };
    },
    changeLength(e) {
      this.campaignFilter.pagination = {
        length: Number(e),
        page: 1,
      };
    },
    routingCampaigns(e) {
      this.$store.dispatch("viewCampaignResult", { campaignCode: e });
    },

    /* Change tab */
    onChangeTabsActive(cur, old) {
      if (cur === old) return;
      switch (cur) {
        case 1:
          this.campaignFilter.status = "All";
          break;
        case 2:
          this.campaignFilter.status = "Active";
          break;
        case 3:
          this.campaignFilter.status = "Inactive";
          break;
      }

      this.campaignFilter = {
        ...this.campaignFilter,
        goals: [EGoal.PAYMENT, EGoal.CREATE_MERCHANT, EGoal.REGISTER],
        sortBy: null,
        sortDictionary: null,
        pagination: {
          length: 10,
          page: 1,
        },
      };
    },

    /* Format Date */
    onChangeFormatDate(e) {
      return this.$dayjs(e).format("DD/MM/YYYY");
    },

    /* Format Goal */
    onChangeFormatGoal(e) {
      switch (e) {
        case EGoal.REGISTER:
          return "Register";
        case EGoal.PAYMENT:
          return "Payment";
        case EGoal.CREATE_MERCHANT:
          return "Create Merchant";
        default:
          return e;
      }
    },

    /* Link */
    onClickLink(e) {
      if (e === null || e === undefined || e === "") {
        return ToastService.showWarning("Campaign not found !");
      } else {
        const baseUrl = process.env.VUE_APP_BASE_URL_PEAK;
        window.open(`${baseUrl}/register/partner?c=${e}`, "_blank")?.focus();
      }
    },
    numberHeader(e): number {
      const header =
        e +
        (this.campaignFilter.pagination.page - 1) *
          this.campaignFilter.pagination.length;
      return header;
    },
  },
  computed: {
    user(): IUser | null {
      return this.$store.getters["auth/user"];
    },
    campaignsPartner(): ICampaignPartner[] {
      return this.$store.getters["campaigns"];
    },
    pagination(): IPagination {
      return this.$store.getters["campaignsPagination"];
    },
    isLoadingCampaigns(): boolean {
      return this.$store.getters["isLoadingCampaigns"];
    },
    isErrorCampaigns(): boolean {
      return this.$store.getters["isErrorCampaigns"];
    },
    errorCampaigns(): string {
      return this.$store.getters["errorCampaigns"];
    },
    getWindowsSize() {
      return this.$store.getters["windowsSize"];
    },
    sortIconStartDate() {
      const sortBy = this.campaignFilter.sortBy;
      const dictionary = this.campaignFilter.sortDictionary;
      if (sortBy === "START_DATE") {
        if (dictionary === "ASC") return "arrow_up";
        else return "arrow_down";
      }
      return "arrow_null";
    },
    sortIconEndDate() {
      const sortBy = this.campaignFilter.sortBy;
      const dictionary = this.campaignFilter.sortDictionary;
      if (sortBy === "END_DATE") {
        if (dictionary === "ASC") return "arrow_up";
        else return "arrow_down";
      }
      return "arrow_null";
    },

    //* TABLE LOGIC *//
    isLoadingCampaignTable(): boolean {
      return this.isLoadingCampaigns && !this.isErrorCampaigns;
    },
    isErrorFoundCampaignTable(): boolean {
      return !this.isLoadingCampaigns && this.isErrorCampaigns;
    },
    isNoDataCampaignTable(): boolean {
      return (
        this.campaigns.length <= 0 &&
        !this.isLoadingCampaigns &&
        !this.isErrorCampaigns
      );
    },
  },
  watch: {
    campaignsPartner(cur) {
      this.campaigns = cur;
    },
    campaignFilter: {
      deep: true,
      handler(cur) {
        if (!cur.partnerCode) return;
        if (this.debounceTimeAction) clearTimeout(this.debounceTimeAction);
        this.debounceTimeAction = setTimeout(() => {
          const payload = { ...cur };
          if (payload.goals.length <= 0) {
            payload.goals = [
              EGoal.REGISTER,
              EGoal.PAYMENT,
              EGoal.CREATE_MERCHANT,
            ];
          }
          stores.dispatch("getCampaignsPartner", payload);
        }, 500);
      },
    },
  },
  unmounted() {
    clearTimeout(this.debounceTimeAction);
  },
});
