
import { defineComponent } from "vue";
export default defineComponent({
  name: "VButtonGroup",
  /* components */
  components: {},
  /* data */
  data() {
    return {
      thisMonth: [
        this.$dayjs().startOf("month").format("YYYY-MM-DD"),
        this.$dayjs().format("YYYY-MM-DD"),
      ] as string[],
      lastMonth: [
        this.$dayjs()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        this.$dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
      ] as string[],
      thisYear: [
        this.$dayjs().startOf("year").format("YYYY-MM-DD"),
        this.$dayjs().endOf("year").format("YYYY-MM-DD"),
      ] as string[],
      lastYear: [
        this.$dayjs().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
        this.$dayjs().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
      ] as string[],
      date: {
        dateStart: this.$dayjs().subtract(30, "day").format("YYYY-MM-DD"),
        dateEnd: this.$dayjs().format("YYYY-MM-DD"),
      },
    };
  },
  /* props */

  /* computed */
  computed: {
    dateStart(): string {
      return this.date.dateStart;
    },
    dateEnd(): string {
      return this.date.dateEnd;
    },
    thisDate(): string {
      return `${this.dateStart} - ${this.dateEnd}`;
    },
    isThisMonth(): boolean {
      return `${this.thisMonth[0]} - ${this.thisMonth[1]}` === this.thisDate;
    },
    isLastMonth(): boolean {
      return `${this.lastMonth[0]} - ${this.lastMonth[1]}` === this.thisDate;
    },
    isThisYear(): boolean {
      return `${this.thisYear[0]} - ${this.thisYear[1]}` === this.thisDate;
    },
    isLastYear(): boolean {
      return `${this.lastYear[0]} - ${this.lastYear[1]}` === this.thisDate;
    },
  },

  /* methods */
  methods: {
    onThisMonth() {
      this.date.dateStart = this.thisMonth[0];
      this.date.dateEnd = this.thisMonth[1];
      this.$emit("date", this.date.dateStart, this.date.dateEnd);
    },
    onLastMonth() {
      this.date.dateStart = this.lastMonth[0];
      this.date.dateEnd = this.lastMonth[1];
      this.$emit("date", this.date.dateStart, this.date.dateEnd);
    },
    onThisYear() {
      this.date.dateStart = this.thisYear[0];
      this.date.dateEnd = this.thisYear[1];
      this.$emit("date", this.date.dateStart, this.date.dateEnd);
    },
    onLastYear() {
      this.date.dateStart = this.lastYear[0];
      this.date.dateEnd = this.lastYear[1];
      this.$emit("date", this.date.dateStart, this.date.dateEnd);
    },
  },
});
