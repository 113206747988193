import axiosInstance from '@/assets/core/axios-instance.core';
import { SECRET_KEY } from '@/types/core/secret.type';
import axios from 'axios';
import { getStorage } from '../utils/storage.util';

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const tokenLocal = getStorage(SECRET_KEY.ACCESS_TOKEN, 'local');
      const tokenSession = getStorage(SECRET_KEY.ACCESS_TOKEN, 'session');

      if (tokenLocal) {
        config.headers['Authorization'] = 'Bearer ' + tokenLocal;
      }
      if (tokenSession) {
        config.headers['Authorization'] = 'Bearer ' + tokenSession;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      console.log('hello')
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== '/auth/sign-in' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
          try {
              const refreshTokenLocal = getStorage(SECRET_KEY.REFRESH_TOKEN, 'local');
            const refreshTokenSession = getStorage(SECRET_KEY.REFRESH_TOKEN, 'session');

            const rs = await axios.post("/auth/refresh-token", {
              refreshToken: refreshTokenLocal ? refreshTokenLocal : refreshTokenSession
            })
            
            const accessToken = rs?.data?.data?.accessToken || null;
            if (!accessToken) throw 'Error refresh token';
            store.dispatch('auth/refreshToken', { accessToken: accessToken });
            
            return axiosInstance(originalConfig);
          } catch (error) {
            store.dispatch('auth/refreshTokenFailed');
            return Promise.reject(error);
          }
        }
      }

      return Promise.reject(err);
    }
  )
}

export default setup;