
import { defineComponent } from "vue";
import CAPITALIZE from "@/assets/utils/capitalize.util";

export default defineComponent({
  name: "CCondition",

  /* -------------------------------------------------------------------------- */
  //*                                    props                                   */
  /* -------------------------------------------------------------------------- */
  props: {
    type: {
      type: String,
      required: true,
      default: () => null,
    },
    data: {
      type: Object,
      required: true,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  /* -------------------------------------------------------------------------- */
  //*                                 components                                 */
  /* -------------------------------------------------------------------------- */
  components: {},

  /* -------------------------------------------------------------------------- */
  //*                                   methods                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    capitalize: CAPITALIZE,
    onCoverMerchantType(e): string {
      if (e === "BUY_AND_SELL_BUSINESS") return "Retail & Trade";
      else if (e === "ALL") return "Other";
      else if (e === "SERVICES_BUSINESS") return "Services";
      else if (e === "REAL_ESTATE_BUSINESS") return "Real Estate Services";
      else if (e === "MANUFACTURING_BUSINESS") return "Manufacturing ";
      else if (e === "ACCOUNTING_OFFICE") return "Accounting Firm";
      else return "-";
    },

    onCoverUserType(e): string {
      if (e === "NONE") return "None";
      else if (e === "OTHER") return "Other";
      else if (e === "BUSINESSOWNER") return "Businessowner";
      else if (e === "BOOKKEEPER") return "Bookkeeper";
      else if (e === "SALESPERSON") return "Salesperson";
      else if (e === "ADMIN") return "Admin";
      else if (e === "ACCOUNTANT") return "Accountant";
      else if (e === "EMPLOYEE") return "Employee";
      else if (e === "ACCOUNTINGFIRM") return "Accountingfirm";
      else if (e === "STUDENT") return "student";
      else return "-";
    },
  },

  /* -------------------------------------------------------------------------- */
  //*                                    watch                                   */
  /* -------------------------------------------------------------------------- */
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler(cur, old) {
        if (cur.actionId) {
          this.condition = [];
        }
        for (const key in cur) {
          if (Object.prototype.hasOwnProperty.call(cur, key)) {
            const value = cur[key];
            if (
              value !== 0 &&
              key !== "actionId" &&
              key !== "type" &&
              value?.length !== 0 &&
              value !== null
            ) {
              this.condition.push({
                [key]: [key],
                value: value,
              });
            }
          }
        }
      },
    },
  },

  /* -------------------------------------------------------------------------- */
  //*                                    data                                    */
  /* -------------------------------------------------------------------------- */
  data() {
    return {
      condition: [] as Array<any>,
    };
  },
});
