/* eslint-disable */
class ValidatorsService {
  // validateEmail = (text: string) =>
  //   /^[A-Za-z]+$^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(text);
  // validateMinLength = (length: number, text: string) =>
  //   text.trim().split(" ").join("").length >= length;

  /* Validate Email */
  validateEmail(el) {
    const re =
      /^(([^<>!()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(el).toLowerCase());
  }
}

export default new ValidatorsService();
