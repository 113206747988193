import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface LayoutState {
  re_size: {
    x: number;
    y: number;
  };
  is_open: boolean;
}

export const DEFAULT_LAYOUT_STATE: LayoutState = {
  re_size: {
    x: 0,
    y: 0,
  },
  is_open: false,
};

export default {
  namespace: true,
  actions,
  state: DEFAULT_LAYOUT_STATE,
  getters,
  mutations,
};
