import IUser from '@/types/core/user.type';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export interface AuthState {
  access_token?: string;
  refreshToken?: string;
  user: IUser | null;
  sign_in_loading: boolean;
  sign_in_error: string | null;
  refresh_token_error: string | null;
}

export const DEFAULT_AUTH_STATE: AuthState = {
  user: null,
  sign_in_loading: false,
  sign_in_error: null,
  refresh_token_error: null
}

export default {
  namespaced: true,
  state: DEFAULT_AUTH_STATE,
  getters,
  mutations,
  actions,
};
