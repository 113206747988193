
import { defineComponent } from "vue";

export default defineComponent({
  name: "PaginationComponent",
  // * data
  data() {
    return {
      selectedOption: null,
      options: [] as unknown[] | any[],
      pageNow: 1,
      perPage: [10, 20, 50, 100],
    };
  },
  // * props
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({ length: 10, page: 1 }),
    },
    totalPage: {
      type: Number,
      required: true,
      default: () => null,
    },
    justifyContent: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  /* -------------------------------------------------------------------------- */
  //*                                   methods                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    //* functions
    nextPage() {
      this.pageNow++;
      this.$emit("page", this.pageNow);
      if (this.pageNow > this.data.page || this.pageNow < 1) {
        this.pageNow = 1;
        return;
      }
    },
    previousPage() {
      this.pageNow--;
      this.$emit("page", this.pageNow);
      if (this.pageNow > this.data.page || this.pageNow < 1) {
        this.pageNow = 1;
        return;
      }
    },

    //* emit event

    /* { "length": 10, "page": 1 } */
    changeLength(e) {
      this.$emit("length", e.target.value);
    },

    changeNumberPerPage(e) {
      this.$emit("perPageEvent", e.target.value);
    },

    selectedPage(e) {
      if (e.target.value) this.pageNow = e.target.value;
      this.$emit("page", e.target.value);
    },
  },
  /* -------------------------------------------------------------------------- */
  //*                                    watch                                   */
  /* -------------------------------------------------------------------------- */
  watch: {
    data() {
      this.pageNow = 1;
    },
  },
  computed: {
    getWindowsSize() {
      return this.$store.getters["windowsSize"];
    },
  },
});
