import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "menus" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuComponent = _resolveComponent("MenuComponent")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_layout = _resolveComponent("v-layout")!

  return (_openBlock(), _createBlock(_component_v_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_navigation_drawer, {
        color: "#3B3C66",
        modelValue: _ctx.drawer,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawer) = $event)),
        location: "left",
        style: {"margin-top":"56px"},
        temporary: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (item, index) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    key: index + 1,
                    to: item.redirect_to,
                    custom: ""
                  }, {
                    default: _withCtx(({ navigate, isActive }) => [
                      _createVNode(_component_MenuComponent, {
                        src: item.src_icon,
                        alt: item.alt,
                        label: item.label,
                        active: isActive,
                        onClick: ($event: any) => (navigate())
                      }, null, 8, ["src", "alt", "label", "active", "onClick"])
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                }), 128))
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}