
import { defineComponent } from "vue";

import MENUS from "@/constants/menus.constant";
import stores from "@/stores";
import MenuComponent from "./Menu.vue";

export default defineComponent({
  name: "SidebarComponent",
  components: { MenuComponent },
  data() {
    return {
      menus: MENUS,
      stores,
    };
  },
  watch: {},
  computed: {},
});
