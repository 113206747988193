export default {
  campaignCodeSelected: ({ campaign_code_selected }) => campaign_code_selected,

  campaignDropdown: ({ campaign_dropdown }) => campaign_dropdown,
  isLoadingCampaignDropdown: ({ loading_campaign_dropdown }) => loading_campaign_dropdown,
  isErrorCampaignDropdown: ({ error_campaign_dropdown }) => !!error_campaign_dropdown,
  errorCampaignDropdown: ({ error_campaign_dropdown }) => error_campaign_dropdown,
  isNoCampaigns: ({ campaign_dropdown, loading_campaign_dropdown, error_campaign_dropdown }) => campaign_dropdown.length <= 0 && !loading_campaign_dropdown && !error_campaign_dropdown,

  campaignInfo: ({ campaign_info }) => campaign_info,
  isLoadingCampaignInfo: ({ loading_campaign_info }) => loading_campaign_info,
  isErrorCampaignInfo: ({ error_campaign_info }) => !!error_campaign_info,
  errorCampaignInfo: ({ error_campaign_info }) => error_campaign_info,
  isNoCampaignInfo: ({ campaign_info, loading_campaign_info, error_campaign_info }) => !campaign_info && !loading_campaign_info && !error_campaign_info,

  campaignCondition: ({ campaign_condition }) => campaign_condition,
  isLoadingCampaignCondition: ({ loading_campaign_condition }) => loading_campaign_condition,
  isErrorCampaignCondition: ({ error_campaign_condition }) => !!error_campaign_condition,
  errorCampaignCondition: ({ error_campaign_condition }) => error_campaign_condition,
  isNoCampaignCondition: ({ campaign_condition, loading_campaign_condition, error_campaign_condition }) => !campaign_condition && !loading_campaign_condition && !error_campaign_condition,

  dashboardSummary: ({ dashboard_summary }) => dashboard_summary,
  isLoadingDashboardSummary: ({ loading_dashboard_summary }) => loading_dashboard_summary,
  isErrorDashboardSummary: ({ error_dashboard_summary }) => !!error_dashboard_summary,
  errorDashboardSummary: ({ error_dashboard_summary }) => error_dashboard_summary,
  isNoDashboardSummary: ({ dashboard_summary, loading_dashboard_summary, error_dashboard_summary }) => !dashboard_summary && !loading_dashboard_summary && !error_dashboard_summary,

  dashboardChart: ({ dashboard_chart }) => dashboard_chart,
  isLoadingDashboardChart: ({ loading_dashboard_chart }) => loading_dashboard_chart,
  isErrorDashboardChart: ({ error_dashboard_chart }) => !!error_dashboard_chart,
  errorDashboardChart: ({ error_dashboard_chart }) => error_dashboard_chart,
  isNoDashboardChart: ({ dashboard_chart, loading_dashboard_chart, error_dashboard_chart }) => dashboard_chart.length <= 0 && !loading_dashboard_chart && !error_dashboard_chart,

  dashboardInfo: ({ dashboard_info }) => dashboard_info,
  isLoadingDashboardInfo: ({ loading_dashboard_info }) => loading_dashboard_info,
  isErrorDashboardInfo: ({ error_dashboard_info }) => !!error_dashboard_info,
  isNoDashboardInfo: ({ dashboard_info, loading_dashboard_info, error_dashboard_info }) => dashboard_info.length <= 0 && !loading_dashboard_info && !error_dashboard_info,
  errorDashboardInfo: ({ error_dashboard_info }) => error_dashboard_info,
  paginationDashboardInfo: ({ pagination_dashboard_info }) => pagination_dashboard_info,

  activityLogs: ({ activity_logs }) => activity_logs,
  isLoadingActivityLogs: ({ loading_activity_logs }) => loading_activity_logs,
  isErrorActivityLogs: ({ error_activity_logs }) => !!error_activity_logs,
  isNoActivityLogs: ({ activity_logs, loading_activity_logs, error_activity_logs }) => activity_logs.length <= 0 && !loading_activity_logs && !error_activity_logs,
  errorActivityLogs: ({ error_activity_logs }) => error_activity_logs,
  paginationActivityLogs: ({ pagination_activity_logs }) => pagination_activity_logs,
}
