
import stores from "@/stores";
import IUser from "@/types/core/user.type";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "NavbarComponent",
  setup() {
    return {
      stores,
    };
  },
  computed: {
    ...mapGetters(["auth", "layout"]),
    user(): IUser {
      return this.$store.getters["auth/user"];
    },
    isVat(): boolean {
      return this.user.isVat;
    },
    userProfile(): string {
      return this.user?.partnerLogo || require("@/assets/svgs/icons/user.svg");
    },
    userProfileAlt(): string {
      return this.user?.partnerName || "User Profile";
    },
    getWindowsSize(): number {
      return this.$store.getters["windowsSize"];
    },
  },
  methods: {
    signOut(): void {
      this.$store.dispatch("auth/signOut");
    },
  },
});
