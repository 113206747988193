import API from '@/assets/core/axios-instance.core';
import { GetCampaignsPartnerPayload, GetCampaignsPartnerResponse } from "@/stores/campaign-partner/dto";

class CampaignPartnerService {
  async getCampaigns(payload: GetCampaignsPartnerPayload): Promise<GetCampaignsPartnerResponse> {
    return API.post(`/affiliate-campaign/get-all`, JSON.parse(JSON.stringify(payload)))
    .then(res => Promise.resolve(res.data))
    .catch(res => Promise.reject(res.response.data));
  }
}
export default new CampaignPartnerService();