import IPagination from '@/types/api/pagination.type';
import ICampaignPartner from '@/types/core/campaign-partner.type';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export interface CampaignPartnerState {
  campaigns: ICampaignPartner[];
  pagination: IPagination;
  is_loading_campaigns: boolean;
  is_error_campaigns: string | null;
}

export const DEFAULT_CAMPAIGN_PARTNER_PAGINATION: IPagination = {
  length: 10,
  page: 1,
  next: 1,
  previous: 1,
  firstPage: 1,
  endPage: 1,
  totalData: 0,
  totalPages: 1,
}

export const DEFAULT_CAMPAIGN_PARTNER_STATE: CampaignPartnerState = {
  campaigns: [],
  pagination: DEFAULT_CAMPAIGN_PARTNER_PAGINATION,
  is_loading_campaigns: false,
  is_error_campaigns: null,
}

export default {
  namespace: true,
  state: DEFAULT_CAMPAIGN_PARTNER_STATE,
  getters,
  mutations,
  actions,
}