
import NavbarComponent from "@/components/layout/Navbar.vue";
import SidebarComponent from "@/components/layout/sidebar/Sidebar.vue";
import SidebarToggleComponent from "@/components/layout/sidebar/SidebarToggle.vue";
import stores from "@/stores";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WithAuthLayout",
  components: { NavbarComponent, SidebarComponent, SidebarToggleComponent },
  setup() {
    return {
      stores,
    };
  },
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      isToggle: false as boolean,
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
  watch: {
    windowSize() {
      stores.dispatch("getWindowsSize", this.windowSize);
    },
  },
});
