
import { defineComponent } from "vue";

export default defineComponent({
  name: "CheckBox",
  data() {
    return {
      isActive: false, // Set the initial value of 'isActive' property
      randomId: "" as string,
    };
  },
  props: {
    label: {
      type: String,
      require: true,
      default: () => null,
    },
    selected: {
      type: Boolean,
      require: true,
      default: () => false,
    },
  },
  watch: {
    isActive: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal === true) {
          return this.$emit("checked", newVal);
        }
        if (newVal === false) {
          return this.$emit("unChecked", newVal);
        }
      },
    },
    isSelected: {
      immediate: true,
      handler(cur) {
        this.$data.isActive = cur;
      },
    },
  },
  computed: {
    isSelected() {
      return this.$props.selected;
    },
  },
});
