import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d63c51e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["menu-container", { active: _ctx.active }]),
    style: _normalizeStyle({
      width: _ctx.width,
      justifyContent: _ctx.justifyContent,
      flexDirection: _ctx.flexDirection,
    }),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick()))
  }, [
    _createElementVNode("img", {
      src: _ctx.src,
      alt: _ctx.alt
    }, null, 8, _hoisted_1),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true)
  ], 6))
}