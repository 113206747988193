import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_tab = _resolveComponent("v-tab", true)!
  const _component_v_tabs = _resolveComponent("v-tabs")!

  return (_openBlock(), _createBlock(_component_v_tabs, {
    modelValue: _ctx.isActive,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isActive) = $event)),
    color: "deep-purple-accent-4",
    "align-tabs": "start"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (tab) => {
        return (_openBlock(), _createBlock(_component_v_tab, {
          key: tab.id,
          value: tab.id,
          style: { textTransform: 'none' }
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              style: _normalizeStyle([{"letter-spacing":"0","font-size":"16px"}, 
          _ctx.isActive === tab.id
            ? { fontWeight: '700', fontStyle: 'normal', lineHeight: 'normal' }
            : ''
        ])
            }, _toDisplayString(tab.name), 5),
            (tab.id === 'Request')
              ? (_openBlock(), _createBlock(_component_v_badge, {
                  key: 0,
                  value: tab.status,
                  color: 'blue',
                  "text-color": 'white',
                  content: tab.status,
                  inline: ""
                }, null, 8, ["value", "content"]))
              : _createCommentVNode("", true),
            (tab.id === 'Pending')
              ? (_openBlock(), _createBlock(_component_v_badge, {
                  key: 1,
                  value: tab.status,
                  color: 'orange',
                  "text-color": 'white',
                  content: tab.status,
                  inline: ""
                }, null, 8, ["value", "content"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}