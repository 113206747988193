import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AppView from "../App.vue";
import ForgotPasswordView from "../views/auth/forgot-password/ForgotPassword.vue";
import SignInView from "../views/auth/sign-in/SignIn.vue";
import AuthView from "./../views/auth/Auth.vue";
import CampaignsView from "../views/partners/campaigns/CampaignView.vue";
import PartnersView from "./../views/partners/Partners.vue";
import ResultsView from "../views/partners/results/ResultView.vue";
import RewardsView from "../views/partners/rewards/RewardView.vue";
import { AUTH_GUARD } from "./guards/auth.guard";
import { NO_AUTH_GUARD } from "./guards/no-auth.guard";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "",
  },
  {
    path: "",
    name: "App",
    component: AppView,
    children: [
      {
        path: "",
        redirect: "/auth",
      },
      {
        path: "/auth",
        name: "Auth",
        component: AuthView,
        beforeEnter: NO_AUTH_GUARD,
        children: [
          {
            path: "",
            redirect: "/auth/sign-in",
          },
          {
            path: "sign-in",
            name: "SignIn",
            component: SignInView,
            beforeEnter: NO_AUTH_GUARD,
          },
          {
            path: "forgot-password",
            name: "ForgotPassword",
            component: ForgotPasswordView,
            beforeEnter: NO_AUTH_GUARD,
          },
          {
            path: "/:pathMatch(.*)*",
            redirect: "/auth/sign-in",
          },
        ],
      },
      {
        path: "/partners",
        name: "Partners",
        beforeEnter: AUTH_GUARD,
        component: PartnersView,
        children: [
          {
            path: "",
            redirect: "/partners/campaigns",
          },
          {
            path: "campaigns",
            name: "Campaigns",
            component: CampaignsView,
            beforeEnter: AUTH_GUARD,
          },
          {
            path: "results",
            name: "Results",
            component: ResultsView,
            beforeEnter: AUTH_GUARD,
          },
          {
            path: "rewards",
            name: "Rewards",
            component: RewardsView,
            beforeEnter: AUTH_GUARD,
          },
          {
            path: "/:pathMatch(.*)*",
            redirect: "/partners/campaigns",
          },
        ],
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: "/auth/sign-in",
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/auth/sign-in",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
