import { TMenus } from "@/types/core/menu.type";

const MENUS: TMenus = [
  {
    label: 'Campaign',
    src_icon: require('@/assets/svgs/icons/bullhorn-solid-icon.svg'),
    alt: 'CAMPAIGN',
    redirect_to: '/partners/campaigns',
  },
  {
    label: 'Result',
    src_icon: require('@/assets/svgs/icons/chart.svg'),
    alt: 'RESULT',
    redirect_to: '/partners/results',
  },
  {
    label: 'Reward',
    src_icon: require('@/assets/svgs/icons/piggy-bank-solid-icon.svg'),
    alt: 'REWARD',
    redirect_to: '/partners/rewards',
  }
];

export default MENUS;