
import SignInFormComponent from "@/components/auth/LoginForm.vue";
import { defineComponent } from "vue";
import { version } from './../../../../package.json';

export default defineComponent({
  name: "SignInPage",
  components: {
    SignInFormComponent,
  },
  data() {
    return {
      version: version || '0.0.0',
    }
  },
  /* computed */
  computed: {
    getSignInError() {
      return this.$store.getters["auth/signInError"];
    },
  },
});
