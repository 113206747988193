import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "container",
    style: _normalizeStyle({
      width: _ctx.width,
      height: _ctx.height,
      textAlign: _ctx.textAlign,
      backgroundColor: _ctx.background,
      minHeight: '10rem',
    })
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 4))
}