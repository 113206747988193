
import { defineComponent } from "vue";

export default defineComponent({
  /* props */
  props: {
    data: {
      type: Array as () => any[],
      required: true,
      default: () => null,
    },
    active: {
      type: String,
      required: true,
      default: null,
    },
  },

  /* watch */
  watch: {
    isActive(cur, old) {
      setTimeout(() => {
        this.$emit("menu", cur, old);
      }, 100);
    },
    active(cur, old) {
      this.isActive = cur;
    },
  },

  /* data */
  data() {
    return {
      isActive: this.active,
    };
  },
});
