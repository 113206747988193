import { SECRET_KEY } from "@/types/core/secret.type";

export function NO_AUTH_GUARD(to, from, next) {
  // let refreshToken: string | null = localStorage.getItem(SECRET_KEY.REFRESH_TOKEN || null);
  const lcToken: string | null = localStorage.getItem(SECRET_KEY.ACCESS_TOKEN || null);
  const ssToken: string | null = sessionStorage.getItem(SECRET_KEY.ACCESS_TOKEN || null);

  if (lcToken || ssToken) {
    return next('/partners/campaigns');
  }

  return next();
}