import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7807bce2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { style: {"letter-spacing":"0px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    variant: "text",
    style: _normalizeStyle({
      borderRadius: _ctx.radius,
      backgroundColor: _ctx.bgColor,
      border: _ctx.bgBorder,
      textTransform: 'none',
      width: _ctx.width,
      color: _ctx.fontColor,
    }),
    disabled: _ctx.isDisable
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, { class: "container" }, {
        default: _withCtx(() => [
          (_ctx.src)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.src,
                alt: "",
                width: "16"
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.message), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["style", "disabled"]))
}