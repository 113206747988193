
//* Import
import { defineComponent } from "vue";
//* Import component
import stores from "@/stores";
import MENUS from "@/constants/menus.constant";
import MenuComponent from "./Menu.vue";

export default defineComponent({
  setup() {
    return {
      stores,
    };
  },
  watch: {
    isToggle(curr) {
      this.drawer = curr;
    },
  },
  data() {
    return {
      drawer: false,
      menus: MENUS,
    };
  },
  props: {
    isToggle: {
      type: Boolean,
      default: false,
    },
  },
  components: { MenuComponent },
});
