export class ShareHelper {
  /* Check Max length Email*/
  checkMaxLengthEmail(email) {
    return email && email.length <= 256;
  }

  /* Check length  */
  checkMaxLength(text, length) {
    return text.length === length;
  }

  /* Check Keyboard */
  checkKeyboard(el) {
    const value = typeof el === "string" ? el : el.target.value;
    if (typeof el === "string") {
      const regex = /^[a-zA-Z0-9$@$!%*?&#^-_--. +()]+$/g;
      if (!regex.test(el)) {
        if (value.length % 5 === 0 && value.length !== 0) {
          return true;
        }
      }
    }
  }
  /* Check Size Screen */
}

export default new ShareHelper();
