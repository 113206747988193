import { __RefreshToken, __SignIn } from "./action-dto";

export default {
  initState({ commit }: any) {
    commit("INIT_STATE");
  },
  signIn({ commit }: any, payload: __SignIn) {
    commit("SIGN_IN", payload);
  },
  signOut({ commit }: any) {
    commit("SIGN_OUT");
    commit("CLEAR_STATE");
  },
  clearState({ commit }: any) {
    commit("CLEAR_STATE");
  },
  refreshToken({ commit }: any, payload: __RefreshToken) {
    commit("REFRESH_TOKEN", payload);
  },
  refreshTokenFailed({ commit }: any) {
    commit("REFRESH_TOKEN_FAILED");
  },
};
