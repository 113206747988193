import API from "@/assets/core/axios-instance.core";
import {
  GetActivityLogsPayload,
  GetActivityLogsResponse,
  GetCampaignConditionPayload,
  GetCampaignConditionResponse,
  GetCampaignDropdownPayload,
  GetCampaignDropdownResponse,
  GetCampaignInfoPayload,
  GetCampaignInfoResponse,
  GetDashboardChartPayload,
  GetDashboardChartResponse,
  GetDashboardInfoPayload,
  GetDashboardInfoResponse,
  GetDashboardSummaryPayload,
  GetDashboardSummaryResponse,
  GetExcelResultPayload,
  GetExcelResultResponse,
} from "@/stores/result/dto";

class ResultService {
  getCampaignDropdown(
    payload: GetCampaignDropdownPayload
  ): Promise<GetCampaignDropdownResponse> {
    return API.post(
      `/affiliate-campaign/drop-down-campaign-name`,
      JSON.parse(JSON.stringify(payload))
    )
      .then((res) => Promise.resolve(res.data))
      .catch((res) => Promise.reject(res.response.data));
  }

  getCampaignInfo(
    payload: GetCampaignInfoPayload
  ): Promise<GetCampaignInfoResponse> {
    return API.get(
      `/affiliate-campaign/get-campaign-info/${payload.campaignCode}`
    )
      .then((res) => Promise.resolve(res.data))
      .catch((res) => Promise.reject(res.response.data));
  }

  getCampaignCondition(
    payload: GetCampaignConditionPayload
  ): Promise<GetCampaignConditionResponse> {
    return API.get(
      `/affiliate-campaign/get-campaign-condition/${payload.campaignCode}`
    )
      .then((res) => Promise.resolve(res.data))
      .catch((res) => Promise.reject(res.response.data));
  }

  getDashboardSummary(
    payload: GetDashboardSummaryPayload
  ): Promise<GetDashboardSummaryResponse> {
    return API.post(
      `/transaction/transaction-find-dashboard`,
      JSON.parse(JSON.stringify(payload))
    )
      .then((res) => Promise.resolve(res.data))
      .catch((res) => Promise.reject(res.response.data));
  }

  getDashboardChart(
    payload: GetDashboardChartPayload
  ): Promise<GetDashboardChartResponse> {
    return API.post(
      `/transaction/transaction-find-list-dashboard`,
      JSON.parse(JSON.stringify(payload))
    )
      .then((res) => Promise.resolve(res.data))
      .catch((res) => Promise.reject(res.response.data));
  }

  getDashboardInfo(
    payload: GetDashboardInfoPayload
  ): Promise<GetDashboardInfoResponse> {
    return API.post(
      `/transaction/transaction-find-list-dashboard-pagination`,
      JSON.parse(JSON.stringify(payload))
    )
      .then((res) => Promise.resolve(res.data))
      .catch((res) => Promise.reject(res.response.data));
  }

  getActivityLogs(
    payload: GetActivityLogsPayload
  ): Promise<GetActivityLogsResponse> {
    return API.post(
      `/activity-log/get-all`,
      JSON.parse(JSON.stringify(payload))
    )
      .then((res) => Promise.resolve(res.data))
      .catch((res) => {
        return Promise.reject(res.response.data);
      });
  }

  getExcelDashboard(payload: GetExcelResultPayload): Promise<any> {
    return API.post(
      `/export-excel/excel-result`,
      JSON.parse(JSON.stringify(payload)),
      {
        responseType: "arraybuffer",
      }
    )
      .then((res) => Promise.resolve(res))
      .catch((res) => Promise.reject(res.response.data));
  }

  getExcelActivityLog(payload: GetExcelResultPayload): Promise<any> {
    return API.post(
      `/export-excel/excel-activitylog`,
      JSON.parse(JSON.stringify(payload)),
      {
        responseType: "arraybuffer",
      }
    )
      .then((res) => Promise.resolve(res))
      .catch((res) => Promise.reject(res.response.data));
  }
}
export default new ResultService();
