
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    message: {
      type: String,
      required: true,
      default: () => null,
    },
    radius: {
      type: String,
      required: false,
      default: () => null,
    },
    bgColor: {
      type: String,
      required: false,
      default: () => null,
    },
    bgBorder: {
      type: String,
      required: false,
      default: () => null,
    },
    width: {
      type: String,
      required: false,
      default: () => "100%",
    },
    height: {
      type: String,
      required: false,
      default: () => "100%",
    },
    textAlign: {
      type: String,
      required: false,
      default: () => null,
    },
    fontColor: {
      type: String,
      required: false,
      default: () => null,
    },
    src: {
      type: String,
      required: false,
      default: () => null,
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
});
