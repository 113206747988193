import { InjectionKey } from "vue";
import { Store, createLogger, createStore } from "vuex";
import auth, { AuthState } from "./auth";
import campaignPartner, { CampaignPartnerState } from "./campaign-partner";
import result, { ResultState } from "./result";
import reward, { RewardState } from "./reward";
import layout, { LayoutState } from "./layout";

export interface STATE {
  auth: AuthState;
  campaignPartner: CampaignPartnerState;
  reward: RewardState;
  layout: LayoutState;
  result: ResultState;
}

export const KEY: InjectionKey<Store<STATE>> = Symbol();

const isProduction: boolean = JSON.parse(process.env.VUE_APP_PRODUCTION);
const stores = createStore({
  modules: {
    auth,
    campaignPartner,
    reward,
    layout,
    result,
  },
  devtools: !isProduction,
  plugins: !isProduction ? [createLogger()] : [],
});

export default stores;
