
import { defineComponent } from "vue";

export default defineComponent({
  name: "VLoading",
  /* props */
  props: {
    color: {
      type: String,
      require: true,
      default: "#3B3C66",
    },
    width: {
      type: Number,
      require: true,
      default: 5,
    },
    size: {
      type: Number,
      require: true,
      default: 25,
    },
  },
});
