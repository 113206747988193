
import { defineComponent } from "vue";

export default defineComponent({
  name: "MenuComponent",
  props: {
    active: {
      type: Boolean,
      require: false,
      default: () => false,
    },
    src: {
      type: String,
      require: false,
      default: () => null,
    },
    alt: {
      type: String,
      require: false,
      default: () => "LOGO MENU",
    },
    label: {
      type: String,
      require: false,
      default: () => null,
    },
    width: {
      type: String,
      require: false,
      default: () => "100%",
    },
    justifyContent: {
      type: String,
      require: false,
      default: () => "left",
    },
    flexDirection: {
      type: String,
      require: false,
      default: () => "row",
    },
  },
  emits: {
    click: (label: string) => label,
  },
  methods: {
    onClick(): void {
      this.$emit("click", this.label);
    },
  },
});
