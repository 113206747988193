import { withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/svgs/icons/toggle.svg'


const _withScopeId = n => (_pushScopeId("data-v-262766c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-container" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "content-router" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavbarComponent = _resolveComponent("NavbarComponent")!
  const _component_SidebarToggleComponent = _resolveComponent("SidebarToggleComponent")!
  const _component_SidebarComponent = _resolveComponent("SidebarComponent")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavbarComponent, null, {
      toggle: _withCtx(() => [
        (_ctx.windowSize.x < 600 || _ctx.windowSize.y < 600)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _imports_0,
              class: "toggle-logo",
              alt: "TOGGLE LOGO",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isToggle = !_ctx.isToggle), ["stop"]))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.windowSize.x < 600 || _ctx.windowSize.y < 600)
        ? (_openBlock(), _createBlock(_component_SidebarToggleComponent, {
            key: 0,
            isToggle: _ctx.isToggle
          }, null, 8, ["isToggle"]))
        : (_openBlock(), _createBlock(_component_SidebarComponent, { key: 1 })),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}