import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19266de2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"white-space":"nowrap"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      width: _ctx.width,
      height: _ctx.height,
      textAlign: _ctx.textAlign,
      backgroundColor: _ctx.background,
      borderRadius: _ctx.borderRadius,
      border: _ctx.borderColor,
    })
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("span", {
      style: _normalizeStyle({ fontSize: _ctx.fontSize, color: _ctx.colorFont, whiteSpace: 'nowrap' })
    }, _toDisplayString(_ctx.value), 5),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 4))
}