import { SECRET_KEY } from '@/types/core/secret.type';

type STORAGE_TYPE = 'local' | 'session';
type Key = keyof typeof SECRET_KEY;

export function getStorage<T = any>(key: Key, storageType: STORAGE_TYPE): T | null {
  let value: T | null = null;
  let find: string | null = null;

  find = storageType === 'local' ? localStorage.getItem(key) : sessionStorage.getItem(key);
  if (find) {
    value = JSON.parse(find);
  }
  return value;
}

export function setStorage<T = any>(key: Key, storageType: STORAGE_TYPE, value: T) {
  if (value === undefined) return;
  storageType === 'local' ? localStorage.setItem(key, JSON.stringify(value)) :  sessionStorage.setItem(key, JSON.stringify(value))
}

export function removeStorage(key: Key, storageType: STORAGE_TYPE) {
  storageType === 'local' ? localStorage.removeItem(key) : sessionStorage.removeItem(key);
}