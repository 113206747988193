
import { defineComponent } from "vue";
import ADD_COMMA from "@/assets/utils/add-comma.util";
import CAPITALIZE from "@/assets/utils/capitalize.util";

export default defineComponent({
  name: "CInfo",
  /* props: */
  props: {
    campaignInfo: {
      type: null,
      required: true,
      default: () => null,
    },
  },
  /* methods: */
  methods: {
    addComma: ADD_COMMA,
    capitalize: CAPITALIZE,
  },
});
