import {
  __GetActivityLogs,
  __GetCampaignCondition,
  __GetCampaignDropdown,
  __GetCampaignInfo,
  __GetDashboardChart,
  __GetDashboardInfo,
  __GetDashboardSummary,
  __GetExcelResult,
  __ViewCampaignResult,
} from "./action-dto";

export default {
  viewCampaignResult({ commit }: any, payload: __ViewCampaignResult) {
    commit("VIEW_CAMPAIGN_RESULT", payload);
  },
  getCampaignDropdown({ commit }: any, payload: __GetCampaignDropdown) {
    commit("GET_CAMPAIGN_DROPDOWN", payload);
  },
  getCampaignInfo({ commit }: any, payload: __GetCampaignInfo) {
    commit("GET_CAMPAIGN_INFO", payload);
  },
  getCampaignCondition({ commit }: any, payload: __GetCampaignCondition) {
    commit("GET_CAMPAIGN_CONDITION", payload);
  },
  getDashboardSummary({ commit }: any, payload: __GetDashboardSummary) {
    commit("GET_DASHBOARD_SUMMARY", payload);
  },
  getDashboardInfo({ commit }: any, payload: __GetDashboardInfo) {
    commit("GET_DASHBOARD_INFO", payload);
  },
  getDashboardChart({ commit }: any, payload: __GetDashboardChart) {
    commit("GET_DASHBOARD_CHART", payload);
  },
  getActivityLogs({ commit }: any, payload: __GetActivityLogs) {
    commit("GET_ACTIVITY_LOGS", payload);
  },
  resetCampaign({ commit }: any) {
    commit("RESET_CAMPAIGN");
  },
  getExcelDashboard({ commit }: any, payload: __GetExcelResult) {
    commit("GET_EXCEL_DASHBOARD", payload);
  },
  getExcelActivityLog({ commit }: any, payload: __GetExcelResult) {
    commit("GET_EXCEL_ACTIVITY_LOG", payload);
  },
};
