import IPagination from "@/types/api/pagination.type";
import IReward from "@/types/core/reward.type";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface RewardState {
  rewards: IReward[];
  pagination: IPagination;
  is_loading_rewards: boolean;
  is_error_rewards: string | null;
  wait_for_request_count: number;
  pending_count: number;
}

export const DEFAULT_REWARD_STATE: RewardState = {
  rewards: [],
  pagination: {
    length: 10,
    page: 1,
    next: 1,
    previous: 1,
    firstPage: 1,
    endPage: 1,
    totalData: 0,
    totalPages: 1,
  },
  is_loading_rewards: false,
  is_error_rewards: null,
  wait_for_request_count: 0,
  pending_count: 0,
};

export default {
  namespace: true,
  state: DEFAULT_REWARD_STATE,
  getters,
  mutations,
  actions,
};
