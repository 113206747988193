import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-691015f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-container" }
const _hoisted_2 = { class: "menus" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuComponent = _resolveComponent("MenuComponent")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (item, index) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: index + 1,
          to: item.redirect_to,
          custom: ""
        }, {
          default: _withCtx(({ navigate, isActive }) => [
            _createVNode(_component_MenuComponent, {
              width: '150px',
              "justify-content": 'center',
              "flex-direction": 'column',
              src: item.src_icon,
              alt: item.alt,
              label: item.label,
              active: isActive,
              onClick: ($event: any) => (navigate())
            }, null, 8, ["src", "alt", "label", "active", "onClick"])
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ]))
}