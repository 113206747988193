import {
  __GetBadgeCount,
  __GetExcelReward,
  __GetRewards,
  __UpdateStatusRewards,
} from "./action-dto";

export default {
  getRewards({ commit }: any, payload: __GetRewards) {
    commit("GET_REWARDS", payload);
  },
  getWaitForRequestCount({ commit }: any, payload: __GetBadgeCount) {
    commit("GET_WAIT_FOR_REQUEST_COUNT", payload);
  },
  getPendingCount({ commit }: any, payload: __GetBadgeCount) {
    commit("GET_PENDING_COUNT", payload);
  },
  updateStatusRewards({ commit }: any, payload: __UpdateStatusRewards) {
    commit("UPDATE_STATUS_REWARDS", payload);
  },
  getExcelReward({ commit }: any, payload: __GetExcelReward) {
    commit("GET_EXCEL_REWARD", payload);
  },
};
