
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    date: {
      type: String,
      default: "",
    },
    min: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: {
        dates: this.date,
      },
      // dateT: "2023/06/18",
    };
  },
  watch: {
    date(newDate) {
      this.data = {
        dates: newDate,
      };
    },
  },
});
