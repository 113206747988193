import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01eb1d3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["min"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_span = _resolveComponent("v-span")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ width: _ctx.width })
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "date",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.dates) = $event)),
      min: _ctx.min
    }, null, 8, _hoisted_1), [
      [_vModelText, _ctx.data.dates]
    ]),
    _createVNode(_component_v_span, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ]),
      _: 1
    })
  ], 4))
}