import IPagination from '@/types/api/pagination.type';
import IActivityLog from '@/types/core/activity-log.type';
import ICampaignDropdown from '@/types/core/campaign-dropdown.type';
import ICampaignInfo from '@/types/core/campaign-info.type';
import IDashboardSummary from '@/types/core/campaign-summary.type';
import IDashboardChart from '@/types/core/dashboard-chart.type';
import IDashboardInfo from '@/types/core/dashboard-info.type';
import TGoalCondition from '@/types/core/goal-condition.type';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export interface ResultState {
  campaign_dropdown: ICampaignDropdown[];
  loading_campaign_dropdown: boolean;
  error_campaign_dropdown: string | null;

  campaign_code_selected: string | null;

  campaign_info: ICampaignInfo | null;
  loading_campaign_info: boolean;
  error_campaign_info: string | null;

  campaign_condition: TGoalCondition | null;
  loading_campaign_condition: boolean;
  error_campaign_condition: string | null;

  dashboard_summary: IDashboardSummary | null;
  loading_dashboard_summary: boolean;
  error_dashboard_summary: string | null;

  dashboard_chart: IDashboardChart[],
  loading_dashboard_chart: boolean;
  error_dashboard_chart: string | null;

  dashboard_info: IDashboardInfo[],
  loading_dashboard_info: boolean;
  error_dashboard_info: string | null;
  pagination_dashboard_info: IPagination;

  activity_logs: IActivityLog[],
  loading_activity_logs: boolean;
  error_activity_logs: string | null;
  pagination_activity_logs: IPagination;
}

export const DEFAULT_PAGINATION_DASHBOARD_INFO: IPagination = {
  length: 20,
  page: 1,
  next: 1,
  previous: 1,
  firstPage: 1,
  endPage: 1,
  totalData: 0,
  totalPages: 1,
}
export const DEFAULT_PAGINATION_ACTIVITY_LOGS: IPagination = {
  length: 20,
  page: 1,
  next: 1,
  previous: 1,
  firstPage: 1,
  endPage: 1,
  totalData: 0,
  totalPages: 1,
}
export const DEFAULT_RESULT_STATE: ResultState = {
  campaign_dropdown: [],
  loading_campaign_dropdown: false,
  error_campaign_dropdown: null,

  campaign_code_selected: null,

  campaign_info: null,
  loading_campaign_info: false,
  error_campaign_info: null,

  campaign_condition: null,
  loading_campaign_condition: false,
  error_campaign_condition: null,

  dashboard_summary: null,
  loading_dashboard_summary: false,
  error_dashboard_summary: null,

  dashboard_chart: [],
  loading_dashboard_chart: false,
  error_dashboard_chart: null,

  dashboard_info: [],
  loading_dashboard_info: false,
  error_dashboard_info: null,
  pagination_dashboard_info: DEFAULT_PAGINATION_DASHBOARD_INFO,

  activity_logs: [],
  loading_activity_logs: false,
  error_activity_logs: null,
  pagination_activity_logs: DEFAULT_PAGINATION_ACTIVITY_LOGS
}

export default {
  namespace: true,
  state: DEFAULT_RESULT_STATE,
  getters,
  mutations,
  actions,
}