import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5439bf0d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative"} }
const _hoisted_2 = ["type", "placeholder"]
const _hoisted_3 = {
  key: 1,
  class: "alertMessage"
}
const _hoisted_4 = {
  class: "title",
  style: {"font-weight":"600"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.LabelMaxlength() != null)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(['count', { textRed: _ctx.LabelMaxlength() < 0 }])
        }, [
          _createElementVNode("span", {
            style: _normalizeStyle([{"font-size":"14px"}, _ctx.spanStyles])
          }, _toDisplayString(_ctx.LabelMaxlength()), 5)
        ], 2))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      style: _normalizeStyle(_ctx.inputStyles),
      type: _ctx.inputType,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.keypress) = $event)),
      placeholder: _ctx.placeholder,
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isTouched = true))
    }, null, 44, _hoisted_2), [
      [_vModelDynamic, _ctx.keypress]
    ]),
    (_ctx.LabelMaxlength() < 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", null, "Please enter up to " + _toDisplayString(_ctx.maxlength) + " characters.", 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.label), 1),
    (_ctx.type === 'password')
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showPass && _ctx.showPass(...args))),
          class: "iconShowPassword"
        }, _toDisplayString(_ctx.inputType === "password" ? "Show password" : "Hide password"), 1))
      : _createCommentVNode("", true)
  ]))
}