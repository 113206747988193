import API from '@/assets/core/axios-instance.core';
import { SignInPayload, SignInResponse } from "@/stores/auth/dto";
import { SECRET_KEY } from "@/types/core/secret.type";

class AuthService {
  getLocalAccessToken() {
    const token = localStorage.getItem(SECRET_KEY.ACCESS_TOKEN);
    return token;
  }

  getLocalRefreshToken() {
    const token = localStorage.getItem(SECRET_KEY.REFRESH_TOKEN);
    return token;
  }

  signIn(payload: SignInPayload): Promise<SignInResponse> {
    return API.post('/auth/login', payload)    
    .then(res => Promise.resolve(res.data))
    .catch(res => Promise.reject(res.response.data));
  }
}

export default new AuthService();
