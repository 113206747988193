import App from "@/App.vue";
import setupInterceptors from "@/assets/core/setup.core";
import "@/assets/styles/styles.scss";
import dayjs from "dayjs";
import { createApp } from "vue";
import InnerImageZoom from 'vue-inner-image-zoom';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import VueApexCharts from "vue3-apexcharts";
import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { createVuetify } from "vuetify/lib/framework.mjs";
import "vuetify/styles";
import router from "./router";
import stores, { KEY } from "./stores";

const vuetify = createVuetify({
  ssr: true,
  components,
  directives,
});

setupInterceptors(stores);

const app = createApp(App);
app.config.globalProperties.$dayjs = dayjs;
app
  .use(Vue3Toastify, {
    autoClose: 3000,
  } as ToastContainerOptions)
  .component('inner-image-zoom', InnerImageZoom)
  .use(vuetify)
  .use(stores, KEY)
  .use(router)
  .use(VueApexCharts);
  
app.mount("#app");
