
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardComponent",
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    getWindowsSize() {
      return this.$store.getters["windowsSize"];
    },
  },
});
