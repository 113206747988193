import { toast } from "vue3-toastify";

/* Warning */
class ToastService {
  showWarning(message) {
    toast.warning(message, {
      autoClose: 10000, // 10 seconds
    });
  }

  /* Success */
  showSuccess(message) {
    toast.success(message, {
      autoClose: 10000, // 10 seconds
    });
  }

  /* Info */
  showInfo(message) {
    toast.info(message, {
      autoClose: 10000, // 10 seconds
    });
  }

  /* Error */
  showError(message) {
    toast.error(message, {
      autoClose: 10000, // 10 seconds
    });
  }
}

export default new ToastService();
