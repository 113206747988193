
import FullScreenLoading from "@/components/shared/loading/full-screen-loading/FullScreenLoading.vue";
import { defineComponent } from "vue";
import NoAuthLayout from "./layouts/no-auth-layout/NoAuthLayout.vue";
import WithAuthLayout from "./layouts/with-auth-layout/WithAuthLayout.vue";
import stores from "./stores";

export default defineComponent({
  name: "AppPage",
  components: { NoAuthLayout, WithAuthLayout, FullScreenLoading },
  mounted() {
    stores.dispatch("auth/initState");
  },
  computed: {
    isSignInLoading(): boolean {
      return this.$store.getters["auth/signInLoading"];
    },
    isLoading(): boolean {
      return (
        this.isSignInLoading || this.isLoadingCampaigns || this.isLoadingRewards
      );
    },
    isSignIn(): boolean {
      return this.$store.getters["auth/isSignIn"];
    },
    isLoadingCampaigns(): boolean {
      return this.$store.getters["isLoadingCampaigns"];
    },
    isLoadingRewards(): boolean {
      return this.$store.getters["isLoadingRewards"];
    },
  },
});
