import BASE_API from '@/types/api/base-api.type';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: BASE_API.AFFILIATE_EXPERIENCE,
  headers: {
    "Content-Type": 'application/json',
  },
});

export default axiosInstance;