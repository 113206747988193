
import { defineComponent } from "vue";

export default defineComponent({
  /* -------------------------------------------------------------------------- */
  //*                                    setup                                   */
  /* -------------------------------------------------------------------------- */
  setup() {
    return {};
  },
  /* -------------------------------------------------------------------------- */
  //*                                    props                                   */
  /* -------------------------------------------------------------------------- */
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100px",
    },
    textAlign: {
      type: String,
      default: "left",
    },
    background: {
      type: String,
      default: "#fff",
    },
    borderRadius: {
      type: String,
      default: "4px",
    },
    borderColor: {
      type: String,
      default: "1px solid #DADBE6",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "18px",
    },
    colorFont: {
      type: String,
      default: "#000",
    },
  },
});
