import clearAuthOnStorage from "@/assets/utils/clear-auth-store.util";
import { getStorage, setStorage } from "@/assets/utils/storage.util";
import router from "@/router";
import AuthService from "@/services/Auth.service";
import { SECRET_KEY } from "@/types/core/secret.type";
import IUser from "@/types/core/user.type";
import { AuthState } from ".";
import { __RefreshToken, __SignIn } from "./action-dto";

/* Service  */
import ToastService from "@/services/Toast.service";

export default {
  INIT_STATE(state: AuthState) {
    const lcToken = getStorage<string>(SECRET_KEY.ACCESS_TOKEN, "local");
    const lcRefreshToken = getStorage<string>(
      SECRET_KEY.REFRESH_TOKEN,
      "local"
    );
    const lcUser = getStorage<IUser>(SECRET_KEY.AUTHENTICATION, "local");

    const ssToken = getStorage<string>(SECRET_KEY.ACCESS_TOKEN, "session");
    const ssRefreshToken = getStorage<string>(
      SECRET_KEY.REFRESH_TOKEN,
      "session"
    );
    const ssUser = getStorage<IUser>(SECRET_KEY.AUTHENTICATION, "session");

    if (lcToken && lcRefreshToken && lcUser) {
      state.access_token = lcToken;
      state.refreshToken = lcRefreshToken;
      state.user = lcUser;
    }

    if (ssToken && ssRefreshToken && ssUser) {
      state.access_token = ssToken;
      state.refreshToken = ssRefreshToken;
      state.user = ssUser;
    }
  },
  SIGN_IN(
    state: AuthState,
    { username, password, keep_me_logged_in }: __SignIn
  ) {
    state.sign_in_loading = true;
    state.sign_in_error = null;

    AuthService.signIn({ username, password })
      .then((res) => {
        const { accessToken, refreshToken, partnerInfo } = res.data;
        state.access_token = accessToken;
        state.sign_in_error = null;
        state.user = partnerInfo;

        if (keep_me_logged_in && partnerInfo && accessToken && refreshToken) {
          setStorage(SECRET_KEY.ACCESS_TOKEN, "local", accessToken);
          setStorage(SECRET_KEY.REFRESH_TOKEN, "local", refreshToken);
          setStorage(SECRET_KEY.AUTHENTICATION, "local", partnerInfo);
        } else {
          if (partnerInfo && accessToken && refreshToken) {
            setStorage(SECRET_KEY.ACCESS_TOKEN, "session", accessToken);
            setStorage(SECRET_KEY.REFRESH_TOKEN, "session", refreshToken);
            setStorage(SECRET_KEY.AUTHENTICATION, "session", partnerInfo);
          }
        }

        router.push("/partners/campaigns");
      })
      .catch((res) => {
        state.access_token = undefined;
        state.refreshToken = undefined;
        state.user = null;
        state.sign_in_error =
          "Email or password is incorrect. Please try again.";
      })
      .finally(() => {
        state.sign_in_loading = false;
      });
  },
  SIGN_OUT(state: AuthState) {
    clearAuthOnStorage();
  },
  CLEAR_STATE(state: AuthState) {
    clearAuthOnStorage();

    state.user = null;
    state.access_token = undefined;
    state.refreshToken = undefined;
    router.push("/auth/sign-in");
  },
  REFRESH_TOKEN(state: AuthState, { accessToken }: __RefreshToken) {
    const tokenLocal = getStorage(SECRET_KEY.ACCESS_TOKEN, "local");
    const tokenSession = getStorage(SECRET_KEY.ACCESS_TOKEN, "session");

    if (tokenLocal) {
      setStorage(SECRET_KEY.ACCESS_TOKEN, "local", accessToken);
    }
    if (tokenSession) {
      setStorage(SECRET_KEY.ACCESS_TOKEN, "session", accessToken);
    }

    state.access_token = accessToken;
    state.refresh_token_error = null;
  },
  REFRESH_TOKEN_FAILED(state: AuthState) {
    clearAuthOnStorage();

    state.user = null;
    state.access_token = undefined;
    state.refreshToken = undefined;
    state.refresh_token_error = "Session expired";
    // ToastService.showError(state.refresh_token_error);
    router.push("/auth/sign-in");
  },
};
