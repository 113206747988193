import { LayoutState } from ".";
import { __GetToggleSidebar, __GetWindowsSize } from "./action-dto";

export default {
  GET_WINDOWS_SIZE(state: LayoutState, payload: __GetWindowsSize) {
    state.re_size = {
      ...payload,
    };
  },

  GET_TOGGLE_SIDEBAR(state: LayoutState, payload: __GetToggleSidebar) {
    state.is_open = payload.isOpen;
  },
};
