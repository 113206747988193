
import ADD_COMMA from "@/assets/utils/add-comma.util";
import { defineComponent } from "vue";

export interface ChartData {
  label: string; // Date format YYYY-MM-DD
  register: number;
  goal_achieved: number;
  conversion: number;
  reward: number;
}

export type Data = ChartData[];

export interface ChartConfig {
  data: Data;
  date_format: "YYYY-MM-DD" | "YYYY-MM" | "YYYY";
  is_show_bar: boolean;
  is_show_goal_line: boolean;
  is_show_register_line: boolean;
}

export default defineComponent({
  name: "ChartComponent",
  data() {
    return {
      containerSize: { width: 0, height: 0 } as {
        width: number;
        height: number;
      },
      graphSize: { width: 0, height: 0 } as { width: number; height: number },
      data: (this.config?.data || []) as Data,
      dataForRender: [] as Data,
      dataRegisterForRender: [] as { x: number; y: number }[],
      dataGoalForRender: [] as { x: number; y: number }[],

      maxHeightBar: 0 as number,
      minHeightBar: 0 as number,

      maxHeightLineRegister: 0 as number,
      minHeightLineRegister: 0 as number,

      maxHeightLineGoalAchieved: 0 as number,
      minHeightLineGoalAchieved: 0 as number,

      lineChartYAxisRight: [] as number[],
      lineHeightYAxisRight: 0 as number,
      lineWidthYAxisRight: 0 as number,

      lineChartYAxisLeft: [] as number[],
      lineHeighYAxisLeft: 0 as number,
      lineWidthYAxisLeft: 0 as number,

      dataSelect: null as ChartData | null,
      togglePopup: false as boolean,
      popupClientX: 0 as number,
      popupClientY: 0 as number,
    };
  },
  props: {
    config: {
      type: Object as () => ChartConfig,
      required: false,
      default: () => ({
        data: [],
        date_format: "YYYY-MM-DD",
        is_show_bar: true,
        is_show_goal_line: true,
        is_show_register_line: true,
      }),
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  mounted() {
    this.matchContainerSize();
    this.matchGraphqlSize();
    this.filterData();
  },
  methods: {
    heightBar(value: number): number {
      const ratio = value * 100 / this.maxHeightBar;
      const gapBetweenYAxis = this.lineHeightYAxisRight * (this.lineChartYAxisRight.length - 1);
      const height = ratio * gapBetweenYAxis / 100;
      return height;
    },
    leftBar(index: number): number {
      return index * (26.5 + (this.widthBar == 0 ? 10 : this.widthBar));
    },
    matchContainerSize() {
      const ref = this.$refs.graphContainer as any;
      const width = ref?.clientWidth;
      const height = ref?.clientHeight;
      this.containerSize = { width, height };
    },
    matchGraphqlSize() {
      const ref = this.$refs.graph as any;
      const width = ref?.clientWidth;
      const height = ref?.clientHeight;
      this.graphSize = { width, height };
    },
    filterData() {
      const data = this.config?.data || [];
      
      const prepareData = data.sort((a: ChartData, b: ChartData) => {
        return this.$dayjs(a.label).year() - this.$dayjs(b.label).year();
      }).map((value) => {
        return {
          ...value,
          label: this.$dayjs(value.label).format(this.config.date_format),
        };
      });
      this.dataForRender = prepareData;
    },
    filterRegisterData() {
      const data: { x: number; y: number }[] = [];
      for (let [index, item] of this.dataForRender.entries()) {
        const leftBar = this.leftBar(index);
        const ratio = (item.register * 100) / (this.maxHeightLineRegister || 0.00001);
        const gapBetweenYAxis = this.lineHeightYAxisRight * (this.lineChartYAxisRight.length - 1);
        const y = (ratio * gapBetweenYAxis) / 100;

        const x = (leftBar + (this.widthBar / 2));
        data.push({
          x: x,
          y: y,
        });
      }
      const y = 0;
      const x = this.graphSize.width;
      data.push({
          x: x,
          y: y,
        });
      this.dataRegisterForRender = data;
    },
    filterGoalData() {
      const data: { x: number; y: number }[] = [];
      for (let [index, item] of this.dataForRender.entries()) {
        const leftBar = this.leftBar(index);
        const ratio = (item.goal_achieved * 100) / (this.maxHeightLineRegister || 0.00001);
        const gapBetweenYAxis = this.lineHeightYAxisRight * (this.lineChartYAxisRight.length - 1);
        const y = (ratio * gapBetweenYAxis) / 100;

        const x = (leftBar + (this.widthBar / 2));
        data.push({
          x: x,
          y: y,
        });
      }
      const y = 0;
      const x = this.graphSize.width;
      data.push({
          x: x,
          y: y,
        });
      this.dataGoalForRender = data;
    },
    hypotenuse(data: { x: number; y: number }[], indexNow: number): number {
      if (indexNow + 1 === data.length) return 0;
      const nextIndex = indexNow + 1;

      const x1 = data[indexNow].x;
      const y1 = data[indexNow].y;
      const x2 = data[nextIndex].x;
      const y2 = data[nextIndex].y;

      const result = Math.sqrt((y1 - y2) ** 2 + (x1 - x2) ** 2);
      return result;
    },
    angle(data: { x: number; y: number }[], indexNow: number): number {
      if (indexNow + 1 === data.length) return 0;
      const nextIndex = indexNow + 1;

      const y1 = data[indexNow].y;
      const y2 = data[nextIndex].y;

      const hypotenuse = this.hypotenuse(data, indexNow);
      const opposite = y1 - y2;

      const angle = Math.asin(opposite / hypotenuse) * (180 / Math.PI) + 0.2;
      // if (Number.isNaN(angle)) return hypotenuse;
      return angle;
    },
    overCursor(e) {
      this.popupClientX = e.clientX - 300;
      this.popupClientY = e.clientY - 500;
    },
    leaveCursor(e) {
      this.dataSelect = null;
    },
    addComma: ADD_COMMA,
    isShowLine(data: { x: number; y: number }[], indexNow: number): boolean {
      if (indexNow + 1 === data.length) return false;
      const nextIndex = indexNow + 1;

      if (
        data[indexNow].x === data[nextIndex].x &&
        data[indexNow].y === data[nextIndex].y
      )
        return false;

      return true;
    },
  },
  computed: {
    dataLength(): number {
      return this.dataForRender.length;
    },
    widthBar(): number {
      return (
        this.graphSize.width / this.dataLength -
        (26.5 * this.dataLength) / this.dataLength
      );
    },
    format(): "YYYY-MM-DD" | "YYYY-MM" | "YYYY" {
      return this.config.date_format;
    },
  },
  watch: {
    data: {
      deep: true,
      handler(cur) {
        this.filterData();
      },
    },
    dataForRender: {
      deep: true,
      handler(_cur, _old) {
        if (JSON.stringify(_old) == JSON.stringify(_cur)) return;
        const cur = _cur.reverse();
        // Height Bar
        this.maxHeightBar = cur.reduce(
          (pre, cur) => (cur.reward > pre ? cur.reward : pre),
          0
        );
        this.minHeightBar = cur.reduce(
          (pre, cur) => (cur.reward < pre ? cur.reward : pre),
          0
        );

        // Height Register Line
        this.maxHeightLineRegister = cur.reduce(
          (pre, cur) => (cur.register > pre ? cur.register : pre),
          0
        );
        this.minHeightLineRegister = cur.reduce(
          (pre, cur) => (cur.register < pre ? cur.register : pre),
          0
        );

        // Height Goal Line
        this.maxHeightLineGoalAchieved = cur.reduce(
          (pre, cur) => (cur.goal_achieved > pre ? cur.goal_achieved : pre),
          0
        );
        this.minHeightLineGoalAchieved = cur.reduce(
          (pre, cur) => (cur.goal_achieved < pre ? cur.goal_achieved : pre),
          0
        );

        // Average Height
        this.maxHeightLineRegister =
          this.maxHeightLineRegister > this.maxHeightLineGoalAchieved
            ? this.maxHeightLineRegister
            : this.maxHeightLineGoalAchieved;
        this.minHeightLineRegister =
          this.minHeightLineRegister < this.minHeightLineGoalAchieved
            ? this.minHeightLineRegister
            : this.minHeightLineGoalAchieved;

        // Y Axis Right
        const averageBar =
          this.maxHeightBar === this.minHeightBar
            ? ((this.maxHeightBar == 0 ? 10 : this.maxHeightBar) + 100) / 7
            : this.maxHeightBar / 7;
        this.lineChartYAxisRight = [];
        for (let i = 7; i > 0; i--) {
          this.lineChartYAxisRight.push(Number((averageBar * i).toFixed(2)));
        }
        this.lineChartYAxisRight.push(0);
        this.lineHeightYAxisRight =
          this.graphSize.height / this.lineChartYAxisRight.length;
        this.lineWidthYAxisRight = this.graphSize.width;

        // Y Axis Left
        const averageLine =
          this.maxHeightLineRegister === this.minHeightLineRegister
            ? ((this.maxHeightLineRegister == 0
                ? 10
                : this.maxHeightLineRegister) +
                100) /
              7
            : this.maxHeightLineRegister / 7;
        this.lineChartYAxisLeft = [];
        for (let i = 7; i > 0; i--) {
          this.lineChartYAxisLeft.push(Number((averageLine * i).toFixed(2)));
        }
        this.lineChartYAxisLeft.push(0);
        this.lineHeighYAxisLeft =
          this.graphSize.height / this.lineChartYAxisLeft.length;
        this.lineWidthYAxisLeft = this.graphSize.width;

        this.filterRegisterData();
        this.filterGoalData();
      },
    },
    format: {
      deep: true,
      handler(cur) {
        this.filterData();
      },
    },
  },
});
