import { SECRET_KEY } from "@/types/core/secret.type";
import { removeStorage } from "./storage.util";

function clearAuthOnStorage() {
  removeStorage(SECRET_KEY.ACCESS_TOKEN, 'local');
  removeStorage(SECRET_KEY.REFRESH_TOKEN, 'local');
  removeStorage(SECRET_KEY.AUTHENTICATION, 'local');
  removeStorage(SECRET_KEY.ACCESS_TOKEN, 'session');
  removeStorage(SECRET_KEY.REFRESH_TOKEN, 'session');
  removeStorage(SECRET_KEY.AUTHENTICATION, 'session');
}
export default clearAuthOnStorage;