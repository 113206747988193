import { __GetToggleSidebar, __GetWindowsSize } from "./action-dto";

export default {
  getWindowsSize({ commit }: any, payload: __GetWindowsSize) {
    commit("GET_WINDOWS_SIZE", payload);
  },

  getToggleSidebar({ commit }: any, payload: __GetToggleSidebar) {
    commit("GET_TOGGLE_SIDEBAR", payload);
  },
};
