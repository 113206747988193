
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    textAlign: {
      type: String,
      default: "left",
    },
    background: {
      type: String,
      default: "#F5F5F7",
    },
  },
});
