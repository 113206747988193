import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33df4d7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullScreenLoading = _resolveComponent("FullScreenLoading")!
  const _component_WithAuthLayout = _resolveComponent("WithAuthLayout")!
  const _component_NoAuthLayout = _resolveComponent("NoAuthLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_FullScreenLoading, {
          key: 0,
          class: "loading-container"
        }))
      : _createCommentVNode("", true),
    (_ctx.isSignIn)
      ? (_openBlock(), _createBlock(_component_WithAuthLayout, { key: 1 }))
      : (_openBlock(), _createBlock(_component_NoAuthLayout, { key: 2 }))
  ]))
}