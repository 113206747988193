import stores from "@/stores";
import { SECRET_KEY } from "@/types/core/secret.type";

export function AUTH_GUARD(to, from, next) {
  const lcToken: string | null = localStorage.getItem(SECRET_KEY.ACCESS_TOKEN || null);
  const ssToken: string | null = sessionStorage.getItem(SECRET_KEY.ACCESS_TOKEN || null);

  if (!!lcToken || !!ssToken) {
    return next();
  }

  stores.dispatch("auth/clearState");
  return next('/auth/sign-in');
}