import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createBlock(_component_v_progress_circular, {
    color: _ctx.color,
    indeterminate: "",
    width: _ctx.width,
    size: _ctx.size
  }, null, 8, ["color", "width", "size"]))
}