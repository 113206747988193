import CampaignPartnerService from "@/services/partners/CampaignPartner.service";
import { CampaignPartnerState, DEFAULT_CAMPAIGN_PARTNER_PAGINATION } from ".";
import { __GetCampaignsPartner } from "./action-dto";

export default {
  GET_CAMPAIGNS_PARTNER(
    state: CampaignPartnerState,
    payload: __GetCampaignsPartner
  ) {
    state.is_loading_campaigns = true;
    state.is_error_campaigns = null;
    CampaignPartnerService.getCampaigns(payload)
      .then((res) => {
        state.campaigns = res.data;
        if (res.pagination) state.pagination = { ...res.pagination };
      })
      .catch((err) => {
        state.is_error_campaigns = err.message;
        state.campaigns = [];
        state.pagination = DEFAULT_CAMPAIGN_PARTNER_PAGINATION;
      })
      .finally(() => {
        setTimeout(() => {
          state.is_loading_campaigns = false;
        }, 500);
      });
  },
};
