
import { defineComponent } from "vue";

export default defineComponent({
  name: "CInputLogin",
  /* props */
  props: {
    placeholder: {
      type: String,
      required: true,
      default: () => null,
    },
    label: {
      type: String,
      required: true,
      default: () => null,
    },
    type: {
      type: String,
      required: true,
      default: () => null,
    },
    maxlength: {
      type: String,
      required: true,
      default: () => null,
    },
    validate: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  /* data */
  data() {
    return {
      keypress: "",
      inputType: "",
      isTouched: false,
    };
  },
  /* watch */
  watch: {
    keypress(newVal) {
      /* Check Keyboard on keypress */
      this.$emit("keypress", newVal, this.type);
    },
  },
  /* methods */
  methods: {
    showPass() {
      if (this.inputType == "password") {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
    LabelMaxlength(): number {
      if (this.maxlength && this.keypress) {
        let maxlength = parseInt(this.maxlength);
        let length = maxlength - this.keypress.toString().length;
        return length;
      } else if (this.maxlength) {
        return parseInt(this.maxlength);
      } else return 0;
    },
  },
  /* mounted */
  mounted() {
    /* Set Input Type */
    this.inputType = this.type;
  },
  /* -------------------------------------------------------------------------- */
  /*                                  computed                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    /* styles scss condition if-else */
    inputStyles() {
      const styles: { [key: string]: string } = {};
      if (this.LabelMaxlength() < 0 || (this.validate && !this.keypress)) {
        styles["border"] = "1px solid red";
      }
      if (this.validate && !this.keypress) {
        styles["padding-left"] = "10px";
      }
      if (this.isTouched && !this.keypress) {
        styles["border"] = "1px solid red";
      }
      if (this.type === "password") {
        styles["padding-right"] = "8rem";
      }
      return styles;
    },
    spanStyles() {
      const styles: { [key: string]: string } = {};
      if (this.LabelMaxlength() < 0) {
        styles["color"] = "red";
      }
      return styles;
    },
  },
});
